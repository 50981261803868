import { useEffect } from 'react';
import './App.css';

function App() {
  useEffect(() => {
    window.location.replace("https://play.google.com/store/apps/details?id=farmstock.agriculture.plants.kisan.krishi");
  }, []);
  return (
    <div className="App">
      
    </div>
  );
}

export default App;
